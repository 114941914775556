import { SVG, SVGProps } from '../SVG';

interface ArrowUpIconProps extends SVGProps {}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.5363 17.1047V4.89539M10.5363 4.89539L4.43164 11M10.5363 4.89539L16.6409 11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { ArrowUpIcon };
