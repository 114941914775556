import { Theme } from '@chakra-ui/react';

const MAX_CONTAINER_WIDTH = '1400px';

// @ts-ignore
export const ChakraDefaultTheme = {
  direction: 'ltr',
  fonts: {
    heading: 'Inter, Almarai',
    body: 'Inter, Almarai',
  },
  styles: {
    global: {
      'html, body': {
        color: 'gray.500',
        fontSize: '14px',
        lineHeight: 1.4,
      },
    },
  },
  colors: {
    gray: {
      25: '#FCFCFD',
      50: '#F9FAFB',
      100: '#F2F4F7',
      200: '#EAECF0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      600: '#475467',
      700: '#344054',
      800: '#1D2939',
      900: '#101828',
      950: '#0C111D',
    },
    success: {
      25: '#F6FEF9',
      50: '#ECFDF3',
      100: '#DCFAE6',
      200: '#ABEFC6',
      300: '#75E0A7',
      400: '#47CD89',
      500: '#17B26A',
      600: '#079455',
      700: '#067647',
      800: '#085D3A',
      900: '#074D31',
    },
    error: {
      25: '#FFFBFA',
      50: '#FEF3F2',
      100: '#FEE4E2',
      200: '#FECDCA',
      300: '#FDA29B',
      400: '#F97066',
      500: '#F04438',
      600: '#D92D20',
      700: '#B42318',
      800: '#912018',
      900: '#7A271A',
    },
  },
  shadows: {
    xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    md: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    lg: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    xl: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
    '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
  },
  components: {
    Container: {
      baseStyle: {
        maxW: MAX_CONTAINER_WIDTH,
      },
    },
    Card: {
      baseStyle: {
        container: {
          backgroundColor: 'transparent',
          boxShadow: 'sm',
          borderWidth: '1px',
          borderColor: 'gray.200',
          borderRadius: '10px',
        },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: 'gray',
      },
      variants: {
        solid: {
          border: '1px solid',
          borderColor: 'brand.700',
          minHeight: '40px',
          padding: '0 16px',
          borderRadius: '8px',
        },
        outline: {
          minHeight: '40px',
          padding: '0 16px',
          borderRadius: '8px',
        },
        link: {
          color: 'gray.600',
          fontWeight: 500,
          width: 'fit-content',
          cursor: 'pointer',
        },
      },
    },
    Heading: {
      baseStyle: {
        color: 'brand.800',
      },
      defaultProps: {
        size: 'lg',
      },
    },
    Badge: {
      variants: {
        outline: {
          textTransform: 'none',
          borderRadius: '16px',
          padding: '2px 8px',
        },
        solid: {
          textTransform: 'none',
          borderRadius: '16px',
          padding: '2px 8px',
        },
        subtle: {
          textTransform: 'none',
          borderRadius: '16px',
          padding: '2px 8px',
          // border: '1px solid',
        },
      },
    },
    Radio: {
      defaultProps: {
        size: 'lg',
      },
      baseStyle: {
        control: {
          _before: {
            content: "''",
            display: 'inline-block',
            position: 'relative',
            width: '50%',
            height: '50%',
            borderRadius: '50%',
            background: 'currentColor',
          },
          color: 'brand.50',
          bgColor: 'brand.50',
          borderColor: 'gray.300',
          _hover: {
            color: 'brand.50',
            bgColor: 'brand.50',
            borderColor: 'brand.600',
          },
          _checked: {
            color: 'brand.600',
            bgColor: 'brand.50',
            borderColor: 'brand.600',
            _hover: {
              color: 'brand.600',
              bgColor: 'brand.50',
              borderColor: 'brand.600',
            },
          },
          _disabled: {
            color: 'brand.50',
            bgColor: 'brand.50',
            borderColor: 'gray.300',
            _hover: {
              color: 'brand.50',
              bgColor: 'brand.50',
              borderColor: 'gray.300',
            },
          },
        },
      },
    },
    Divider: {
      baseStyle: {
        opacity: '1',
        borderColor: 'gray.200',
      },
    },
    Progress: {
      defaultProps: {
        size: 'sm',
      },
      baseStyle: {
        track: {
          bg: 'brand.200',
          shadow: 'xs',
          border: '1px solid',
          borderRadius: '0',
          borderColor: 'brand.400',
        },
        filledTrack: {
          bg: 'brand.800',
          borderRadius: '0px',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          fontSize: '16px',
          color: 'brand.800',
          height: '40px',
        },
      },
    },
    Textarea: {
      baseStyle: {
        fontSize: '16px',
        color: 'brand.800',
      },
    },
    FormLabel: {
      baseStyle: {
        color: 'brand.800',
      },
    },
    Accordion: {
      baseStyle: {
        button: {
          padding: '12px 16px',
          backgroundColor: 'brand.50',
        },
        panel: {
          padding: '12px 16px',
        },
      },
    },
  },
} as Partial<Theme>;
