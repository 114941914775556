import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  FormHelperText,
  FormErrorMessage,
  Textarea,
  Stack,
  Button,
} from '@chakra-ui/react';
import { FormFieldEnum, TChatMessage, TFormFieldValue } from '../types';
import { NormalMessage } from './NormalMessage';

interface FormMessageProps {
  message: TChatMessage;
  isLastMessage: boolean;
  onSubmitForm: (fields: TFormFieldValue[]) => void;
  onSendMessage: (message: string) => void;
}

const InputMapper = {
  [FormFieldEnum.Text]: ChakraInput,
  [FormFieldEnum.Phone]: ChakraInput,
  [FormFieldEnum.Email]: ChakraInput,
  [FormFieldEnum.Radio]: ChakraInput,
  [FormFieldEnum.Select]: ChakraInput,
  [FormFieldEnum.Otp]: ChakraInput,
  [FormFieldEnum.Textarea]: Textarea,
  [FormFieldEnum.Checkbox]: ChakraInput,
  [FormFieldEnum.Password]: ChakraInput,
};

const FormMessage: React.FC<FormMessageProps> = ({
  message,
  isLastMessage,
  onSubmitForm,
  onSendMessage,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [discarded, setDiscarded] = useState(false);

  const handleDiscard = () => {
    setDiscarded(true);
    onSendMessage('I will not submit now, still having more questions to ask.');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formEntries = [...(formData as any).entries()];
    const formFields = formEntries.map((entry) => ({
      label: entry[0] as string,
      value: entry[1] as string,
      type: 'text',
    }));
    onSubmitForm(formFields);
    setSubmitted(true);
  };

  if (discarded) {
    return <>Form has been discarded</>;
  }

  if (submitted) {
    return <>Form has been submitted</>;
  }

  if (!isLastMessage) {
    return <>Outdated form</>;
  }

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Stack spacing="20px">
        <NormalMessage message={message} />

        {message.formFields?.map((field, index) => {
          const Input: any = InputMapper[field.type];
          const error = '';
          const isInvalid = false;
          const isDisabled = false;
          const isRequired = false;
          const isReadOnly = false;
          return (
            <FormControl
              key={index}
              isInvalid={isInvalid}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isReadOnly={isReadOnly}
              maxW="80%"
            >
              {field.label && <FormLabel>{field.label}</FormLabel>}
              <Input
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                borderColor="#B3B8DB"
                background="white"
                borderRadius="8px"
              />
              {error && isInvalid ? (
                <FormErrorMessage>{error}</FormErrorMessage>
              ) : field.description ? (
                <FormHelperText>{field.description}</FormHelperText>
              ) : null}
            </FormControl>
          );
        })}

        <Stack direction="row">
          <Button variant="outline" onClick={handleDiscard}>
            Discard
          </Button>
          <Button colorScheme="blue" type="submit">
            Submit
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export { FormMessage };
