import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import {
  AuthProvider,
  LocaleProvider,
  StandaloneSecure,
} from '@wuilt/app-core';

import { router } from './app/app';

const { NX_ACCOUNTS_API_ENDPOINT } = process.env;
const domain = window.location.hostname;
const arr = domain.split('.');
const TLD = arr[arr.length - 1];
const authRoute = `https://app.wuilt.${TLD}/account/login?redirectOut=${window.location.href}`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <LocaleProvider>
      <AuthProvider
        baseEndpoint={NX_ACCOUNTS_API_ENDPOINT! || 'https://accounts.wuilt.dev'}
      >
        <StandaloneSecure authRoute={authRoute}>
          <RouterProvider router={router} />
        </StandaloneSecure>
      </AuthProvider>
    </LocaleProvider>
  </StrictMode>,
);
