import { Text } from '@chakra-ui/react';
import React from 'react';

interface ChatDisclaimerProps {}

const ChatDisclaimer: React.FC<ChatDisclaimerProps> = ({}) => {
  return (
    <Text fontSize="10px" color="gray.500" textAlign="center" padding="8px">
      This AI can make mistakes, consider checking important information
    </Text>
  );
};

export { ChatDisclaimer };
