import { Progress } from '@chakra-ui/react';
import React from 'react';
import { useNavigation } from 'react-router-dom';

interface LoadingBarProps {}

const LoadingBar: React.FC<LoadingBarProps> = ({}) => {
  const navigation = useNavigation();
  const isLoading =
    navigation.state === 'loading' || navigation.state === 'submitting';

  if (isLoading) {
    return <Progress isIndeterminate pos="fixed" left={0} right={0} top={0} />;
  }
  return null;
};

export default LoadingBar;
