import { Stack, IconButton, Text, Box, Textarea } from '@chakra-ui/react';
import { ArrowUpIcon } from '@leap/react-icons';
import React, { useRef } from 'react';
import { TUserInfo } from '../types';
import { useColors } from '../hooks';

interface ChatInputProps {
  isTyping: boolean;
  receiverInfo: TUserInfo;
  senderInfo: TUserInfo;
  isDisabled: boolean;
  onSendMessage: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  isTyping,
  receiverInfo,
  isDisabled,
  onSendMessage,
}) => {
  const { primaryColor, secondaryColor } = useColors();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const message = formData.get('message') as string;
    if (!message.length) return;
    onSendMessage(message);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Adjust the textarea height
    const element = event.currentTarget;
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Submit on pressing enter
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      buttonRef.current?.click?.();
      const element = event.currentTarget;
      element.style.height = 'auto';
    }
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box
        background={`linear-gradient(to right, ${secondaryColor}, ${primaryColor})`}
        borderRadius="14px"
        boxShadow="0px 4px 6px -2px rgba(78, 91, 166, 0.03), 0px 12px 16px -4px rgba(78, 91, 166, 0.10)"
        padding="2px"
      >
        <Stack
          position="relative"
          direction="row"
          align="center"
          padding="0px 10px"
          borderRadius="12px"
          background="white"
          minHeight="48px"
        >
          <Textarea
            ref={inputRef}
            rows={1}
            spellCheck={false}
            maxHeight="200px"
            resize="none"
            name="message"
            variant="unstyled"
            placeholder={
              isDisabled ? undefined : `Message ${receiverInfo.name}`
            }
            _placeholder={{ color: 'gray.500' }}
            isDisabled={isDisabled}
            autoComplete="off"
            color="black"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            ref={buttonRef}
            type="submit"
            aria-label="Send"
            title="Send"
            colorScheme="gray"
            variant="outline"
            icon={<ArrowUpIcon />}
            isDisabled={isTyping || isDisabled}
            height="30px"
            minHeight="30px"
            width="30px"
            minWidth="30px"
          />
          {isDisabled && (
            <Box position="absolute" top="50%" transform="translateY(-50%)">
              <Text>Respond to the form...</Text>
            </Box>
          )}
        </Stack>
      </Box>
    </form>
  );
};

export { ChatInput };
