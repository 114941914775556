import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import React from 'react';

interface BeatLoaderProps {}

const BeatLoader: React.FC<BeatLoaderProps> = ({}) => {
  return (
    <span>
      <StyledEdgeBullet />
      <StyledCenterBullet />
      <StyledEdgeBullet />
    </span>
  );
};

export { BeatLoader };

/**
 * Styles
 */

const beat = keyframes`
  50% {
    transform: scale(0.75);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledEdgeBullet = styled.span`
  display: inline-block;
  background-color: black;
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
  animation: 0.7s linear 0s infinite normal both running ${beat};
`;

const StyledCenterBullet = styled(StyledEdgeBullet)`
  animation: 0.7s linear 0.35s infinite normal both running ${beat};
`;
