export enum ChatMessageRoleEnum {
  Sender = 'SENDER',
  Receiver = 'RECEIVER',
}

export enum ChatMessageTypeEnum {
  CollectInput = 'COLLECT_INPUT',
  Normal = 'NORMAL',
}

export enum FormFieldEnum {
  Text = 'TEXT',
  Phone = 'PHONE',
  Email = 'EMAIL',
  Radio = 'RADIO',
  Select = 'SELECT',
  Otp = 'OTP',
  Textarea = 'TEXTAREA',
  Checkbox = 'CHECKBOX',
  Password = 'PASSWORD',
}

export type TFormField = {
  name?: string;
  label?: string;
  description?: string;
  placeholder?: string;
  type: FormFieldEnum;
};

export type TChatMessage = {
  id: string;
  type: ChatMessageTypeEnum;
  role: ChatMessageRoleEnum;
  content: React.ReactNode;
  formFields?: TFormField[];
};

export type TUserInfo = {
  name: string;
  image: string;
};

export type TFormFieldValue = {
  label: string;
  value: string;
  type: string;
};
