import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import { DocumentNode } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
};

export type Analytics = {
  __typename?: 'Analytics';
  conversationCount: Scalars['Int']['output'];
  conversionRate: Scalars['Float']['output'];
  formSubmissionCount: Scalars['Int']['output'];
};

export type AssistantFile = {
  __typename?: 'AssistantFile';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
  website: AssistantWebsite;
};

export type AssistantThread = {
  __typename?: 'AssistantThread';
  id: Scalars['String']['output'];
  messages: Array<ThreadMessage>;
  sessionId: Scalars['String']['output'];
  website: AssistantWebsite;
};

export type AssistantWebsite = {
  __typename?: 'AssistantWebsite';
  analytics: Analytics;
  conversationStarters: Array<WebsiteQuickAction>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Domain>;
  emails?: Maybe<Array<Scalars['String']['output']>>;
  files: Array<AssistantFile>;
  font?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructions: Scalars['String']['output'];
  knowledgeUrls: Array<KnowledgeUrl>;
  logoURL?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  reachedFreeLimit: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  subHeadline?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<SubscriptionEntity>;
};

export type CollectInputField = {
  __typename?: 'CollectInputField';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  placeholder: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CreateAssistantInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['String']['input']>;
  instructions: Scalars['String']['input'];
  logoURL?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Domain = {
  __typename?: 'Domain';
  id?: Maybe<Scalars['ID']['output']>;
  isInternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** File update modes */
export enum FileUpdateMode {
  Append = 'APPEND',
  Remove = 'REMOVE',
}

export type FormField = {
  label: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type GeneratePreSignedUrlInput = {
  fileName: Scalars['String']['input'];
  fileSizeInBytes: Scalars['Float']['input'];
  isLogo: Scalars['Boolean']['input'];
  mimeType: Scalars['String']['input'];
  websiteId: Scalars['String']['input'];
};

export type GeneratePreSignedUrlResponse = {
  __typename?: 'GeneratePreSignedURLResponse';
  parameters: Array<PreSignedUrlParameters>;
  resourceUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type KnowledgeUrl = {
  __typename?: 'KnowledgeUrl';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: ScrapingStatus;
  url: Scalars['String']['output'];
  website: AssistantWebsite;
};

export type MessageAdded = {
  __typename?: 'MessageAdded';
  content: Scalars['String']['output'];
  threadId: Scalars['String']['output'];
};

export enum MessageType {
  CollectInput = 'COLLECT_INPUT',
  Normal = 'NORMAL',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds a new knowledge URL and returns all knowledge URLs for the website. */
  addKnowledgeUrl: Array<KnowledgeUrl>;
  connectExternalDomain: Domain;
  createAssistantWebsite: AssistantWebsite;
  createPaymentUrl: Scalars['String']['output'];
  deleteKnowledgeUrl: Array<KnowledgeUrl>;
  disconnectDomain: Domain;
  generatePreSignedURL: GeneratePreSignedUrlResponse;
  refreshKnowledgeUrl: Array<KnowledgeUrl>;
  replayToAssistantThread: AssistantThread;
  startAssistantThread: AssistantThread;
  submitForm: Scalars['Boolean']['output'];
  updateAssistantWebsite: AssistantWebsite;
};

export type MutationAddKnowledgeUrlArgs = {
  url: Scalars['String']['input'];
  websiteId: Scalars['String']['input'];
};

export type MutationConnectExternalDomainArgs = {
  domainName: Scalars['String']['input'];
  websiteId: Scalars['ID']['input'];
};

export type MutationCreateAssistantWebsiteArgs = {
  input: CreateAssistantInput;
};

export type MutationCreatePaymentUrlArgs = {
  cancelUrl: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  successUrl: Scalars['String']['input'];
  websiteId: Scalars['ID']['input'];
};

export type MutationDeleteKnowledgeUrlArgs = {
  id: Scalars['ID']['input'];
  websiteId: Scalars['String']['input'];
};

export type MutationDisconnectDomainArgs = {
  domainId: Scalars['ID']['input'];
};

export type MutationGeneratePreSignedUrlArgs = {
  input: GeneratePreSignedUrlInput;
};

export type MutationRefreshKnowledgeUrlArgs = {
  id: Scalars['ID']['input'];
  websiteId: Scalars['String']['input'];
};

export type MutationReplayToAssistantThreadArgs = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type MutationStartAssistantThreadArgs = {
  message: Scalars['String']['input'];
  sessionId: Scalars['ID']['input'];
  websiteId: Scalars['ID']['input'];
};

export type MutationSubmitFormArgs = {
  input: SubmitFormInput;
};

export type MutationUpdateAssistantWebsiteArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAssistantInput;
};

export type Plan = {
  __typename?: 'Plan';
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  includesDomain: Scalars['Boolean']['output'];
  period: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type PreSignedUrlParameters = {
  __typename?: 'PreSignedURLParameters';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  assistantThread?: Maybe<AssistantThread>;
  assistantWebsite?: Maybe<AssistantWebsite>;
  assistantWebsiteBySlug?: Maybe<AssistantWebsite>;
  assistantWebsites: Array<AssistantWebsite>;
  getKnowledgeUrls: Array<KnowledgeUrl>;
  getPlans?: Maybe<Array<Plan>>;
  getStripeCustomerPortalUrl: Scalars['String']['output'];
  getWebsiteByDomain?: Maybe<AssistantWebsite>;
  sessionThreads: Array<AssistantThread>;
};

export type QueryAssistantThreadArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssistantWebsiteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssistantWebsiteBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryGetKnowledgeUrlsArgs = {
  ids: Array<Scalars['ID']['input']>;
  websiteId: Scalars['String']['input'];
};

export type QueryGetWebsiteByDomainArgs = {
  domainName: Scalars['String']['input'];
};

export type QuerySessionThreadsArgs = {
  sessionId: Scalars['ID']['input'];
  websiteId: Scalars['ID']['input'];
};

export type QuickActionsInput = {
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum ScrapingStatus {
  Done = 'DONE',
  Pending = 'PENDING',
}

export type SubmitFormInput = {
  fields: Array<FormField>;
  threadId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  messageAdded?: Maybe<MessageAdded>;
};

export type SubscriptionMessageAddedArgs = {
  threadId: Scalars['String']['input'];
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  plan: Plan;
};

export type ThreadMessage = {
  __typename?: 'ThreadMessage';
  content: Scalars['String']['output'];
  fields?: Maybe<Array<CollectInputField>>;
  id: Scalars['String']['output'];
  role: Scalars['String']['output'];
  type: MessageType;
};

export type UpdateAssistantInput = {
  conversationStarters?: InputMaybe<Array<QuickActionsInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  files?: InputMaybe<UpdateFilesInput>;
  font?: InputMaybe<Scalars['String']['input']>;
  fontColor?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  logoURL?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subHeadline?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFilesInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  mode: FileUpdateMode;
  urls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebsiteQuickAction = {
  __typename?: 'WebsiteQuickAction';
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
  website: AssistantWebsite;
};

export type MessageFragment = {
  __typename?: 'ThreadMessage';
  id: string;
  role: string;
  type: MessageType;
  content: string;
  fields?: Array<{
    __typename?: 'CollectInputField';
    type: string;
    label: string;
    description: string;
    placeholder: string;
  }> | null;
};

export type QuickActionFragment = {
  __typename?: 'WebsiteQuickAction';
  id: string;
  title: string;
  message: string;
};

export type FileFragment = {
  __typename?: 'AssistantFile';
  createdAt: any;
  id: string;
  url: string;
};

export type SubscriptionFragment = {
  __typename?: 'SubscriptionEntity';
  id: string;
  active: boolean;
};

export type PlanFragment = { __typename?: 'Plan'; id: string; price: number };

export type AnalyticsFragment = {
  __typename?: 'Analytics';
  conversationCount: number;
  conversionRate: number;
  formSubmissionCount: number;
};

export type KnowledgeUrlFragment = {
  __typename?: 'KnowledgeUrl';
  id: string;
  createdAt: any;
  url: string;
  status: ScrapingStatus;
};

export type WebsiteFragment = {
  __typename?: 'AssistantWebsite';
  id: string;
  name: string;
  font?: string | null;
  logoURL?: string | null;
  headline?: string | null;
  fontColor?: string | null;
  subHeadline?: string | null;
  description?: string | null;
  reachedFreeLimit: boolean;
  domain?: {
    __typename?: 'Domain';
    name: string;
    id?: string | null;
    isInternal: boolean;
  } | null;
  conversationStarters: Array<{
    __typename?: 'WebsiteQuickAction';
    id: string;
    title: string;
    message: string;
  }>;
};

export type AdminWebsiteFragment = {
  __typename?: 'AssistantWebsite';
  instructions: string;
  emails?: Array<string> | null;
  id: string;
  name: string;
  font?: string | null;
  logoURL?: string | null;
  headline?: string | null;
  fontColor?: string | null;
  subHeadline?: string | null;
  description?: string | null;
  reachedFreeLimit: boolean;
  files: Array<{
    __typename?: 'AssistantFile';
    createdAt: any;
    id: string;
    url: string;
  }>;
  subscription?: {
    __typename?: 'SubscriptionEntity';
    id: string;
    active: boolean;
  } | null;
  analytics: {
    __typename?: 'Analytics';
    conversationCount: number;
    conversionRate: number;
    formSubmissionCount: number;
  };
  knowledgeUrls: Array<{
    __typename?: 'KnowledgeUrl';
    id: string;
    createdAt: any;
    url: string;
    status: ScrapingStatus;
  }>;
  domain?: {
    __typename?: 'Domain';
    name: string;
    id?: string | null;
    isInternal: boolean;
  } | null;
  conversationStarters: Array<{
    __typename?: 'WebsiteQuickAction';
    id: string;
    title: string;
    message: string;
  }>;
};

export type CreateWebsiteMutationVariables = Exact<{
  input: CreateAssistantInput;
}>;

export type CreateWebsiteMutation = {
  __typename?: 'Mutation';
  createAssistantWebsite: {
    __typename?: 'AssistantWebsite';
    instructions: string;
    emails?: Array<string> | null;
    id: string;
    name: string;
    font?: string | null;
    logoURL?: string | null;
    headline?: string | null;
    fontColor?: string | null;
    subHeadline?: string | null;
    description?: string | null;
    reachedFreeLimit: boolean;
    files: Array<{
      __typename?: 'AssistantFile';
      createdAt: any;
      id: string;
      url: string;
    }>;
    subscription?: {
      __typename?: 'SubscriptionEntity';
      id: string;
      active: boolean;
    } | null;
    analytics: {
      __typename?: 'Analytics';
      conversationCount: number;
      conversionRate: number;
      formSubmissionCount: number;
    };
    knowledgeUrls: Array<{
      __typename?: 'KnowledgeUrl';
      id: string;
      createdAt: any;
      url: string;
      status: ScrapingStatus;
    }>;
    domain?: {
      __typename?: 'Domain';
      name: string;
      id?: string | null;
      isInternal: boolean;
    } | null;
    conversationStarters: Array<{
      __typename?: 'WebsiteQuickAction';
      id: string;
      title: string;
      message: string;
    }>;
  };
};

export type UpdateWebsiteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAssistantInput;
}>;

export type UpdateWebsiteMutation = {
  __typename?: 'Mutation';
  updateAssistantWebsite: {
    __typename?: 'AssistantWebsite';
    instructions: string;
    emails?: Array<string> | null;
    id: string;
    name: string;
    font?: string | null;
    logoURL?: string | null;
    headline?: string | null;
    fontColor?: string | null;
    subHeadline?: string | null;
    description?: string | null;
    reachedFreeLimit: boolean;
    files: Array<{
      __typename?: 'AssistantFile';
      createdAt: any;
      id: string;
      url: string;
    }>;
    subscription?: {
      __typename?: 'SubscriptionEntity';
      id: string;
      active: boolean;
    } | null;
    analytics: {
      __typename?: 'Analytics';
      conversationCount: number;
      conversionRate: number;
      formSubmissionCount: number;
    };
    knowledgeUrls: Array<{
      __typename?: 'KnowledgeUrl';
      id: string;
      createdAt: any;
      url: string;
      status: ScrapingStatus;
    }>;
    domain?: {
      __typename?: 'Domain';
      name: string;
      id?: string | null;
      isInternal: boolean;
    } | null;
    conversationStarters: Array<{
      __typename?: 'WebsiteQuickAction';
      id: string;
      title: string;
      message: string;
    }>;
  };
};

export type GeneratePresignedUrlMutationVariables = Exact<{
  input: GeneratePreSignedUrlInput;
}>;

export type GeneratePresignedUrlMutation = {
  __typename?: 'Mutation';
  generatePreSignedURL: {
    __typename?: 'GeneratePreSignedURLResponse';
    url: string;
    resourceUrl: string;
    parameters: Array<{
      __typename?: 'PreSignedURLParameters';
      name: string;
      value: string;
    }>;
  };
};

export type StartAssistantThreadMutationVariables = Exact<{
  websiteId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
}>;

export type StartAssistantThreadMutation = {
  __typename?: 'Mutation';
  thread: {
    __typename?: 'AssistantThread';
    id: string;
    messages: Array<{
      __typename?: 'ThreadMessage';
      id: string;
      role: string;
      type: MessageType;
      content: string;
      fields?: Array<{
        __typename?: 'CollectInputField';
        type: string;
        label: string;
        description: string;
        placeholder: string;
      }> | null;
    }>;
  };
};

export type ReplayToThreadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
}>;

export type ReplayToThreadMutation = {
  __typename?: 'Mutation';
  replay: {
    __typename?: 'AssistantThread';
    id: string;
    messages: Array<{
      __typename?: 'ThreadMessage';
      id: string;
      role: string;
      type: MessageType;
      content: string;
      fields?: Array<{
        __typename?: 'CollectInputField';
        type: string;
        label: string;
        description: string;
        placeholder: string;
      }> | null;
    }>;
  };
};

export type SubmitFormMutationVariables = Exact<{
  input: SubmitFormInput;
}>;

export type SubmitFormMutation = {
  __typename?: 'Mutation';
  submitForm: boolean;
};

export type CreatePaymentUrlMutationVariables = Exact<{
  planId: Scalars['ID']['input'];
  websiteId: Scalars['ID']['input'];
  successUrl: Scalars['String']['input'];
  cancelUrl: Scalars['String']['input'];
}>;

export type CreatePaymentUrlMutation = {
  __typename?: 'Mutation';
  createPaymentUrl: string;
};

export type AddKnowledgeUrlMutationVariables = Exact<{
  url: Scalars['String']['input'];
  websiteId: Scalars['String']['input'];
}>;

export type AddKnowledgeUrlMutation = {
  __typename?: 'Mutation';
  knowledgeUrls: Array<{
    __typename?: 'KnowledgeUrl';
    createdAt: any;
    id: string;
    url: string;
    status: ScrapingStatus;
  }>;
};

export type DeleteKnowledgeUrlMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  websiteId: Scalars['String']['input'];
}>;

export type DeleteKnowledgeUrlMutation = {
  __typename?: 'Mutation';
  knowledgeUrls: Array<{
    __typename?: 'KnowledgeUrl';
    createdAt: any;
    id: string;
    url: string;
    status: ScrapingStatus;
  }>;
};

export type RefreshKnowledgeUrlMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  websiteId: Scalars['String']['input'];
}>;

export type RefreshKnowledgeUrlMutation = {
  __typename?: 'Mutation';
  knowledgeUrls: Array<{
    __typename?: 'KnowledgeUrl';
    createdAt: any;
    id: string;
    url: string;
    status: ScrapingStatus;
  }>;
};

export type ConnectExternalDomainMutationVariables = Exact<{
  domainName: Scalars['String']['input'];
  websiteId: Scalars['ID']['input'];
}>;

export type ConnectExternalDomainMutation = {
  __typename?: 'Mutation';
  connectExternalDomain: {
    __typename?: 'Domain';
    id?: string | null;
    isInternal: boolean;
    name: string;
  };
};

export type DisConnectDomainMutationVariables = Exact<{
  domainId: Scalars['ID']['input'];
}>;

export type DisConnectDomainMutation = {
  __typename?: 'Mutation';
  disconnectDomain: {
    __typename?: 'Domain';
    id?: string | null;
    isInternal: boolean;
    name: string;
  };
};

export type Message_AddedSubscriptionVariables = Exact<{
  threadId: Scalars['String']['input'];
}>;

export type Message_AddedSubscription = {
  __typename?: 'Subscription';
  messageAdded?: {
    __typename?: 'MessageAdded';
    content: string;
    threadId: string;
  } | null;
};

export type MyWebsitesQueryVariables = Exact<{ [key: string]: never }>;

export type MyWebsitesQuery = {
  __typename?: 'Query';
  assistantWebsites: Array<{
    __typename?: 'AssistantWebsite';
    instructions: string;
    emails?: Array<string> | null;
    id: string;
    name: string;
    font?: string | null;
    logoURL?: string | null;
    headline?: string | null;
    fontColor?: string | null;
    subHeadline?: string | null;
    description?: string | null;
    reachedFreeLimit: boolean;
    files: Array<{
      __typename?: 'AssistantFile';
      createdAt: any;
      id: string;
      url: string;
    }>;
    subscription?: {
      __typename?: 'SubscriptionEntity';
      id: string;
      active: boolean;
    } | null;
    analytics: {
      __typename?: 'Analytics';
      conversationCount: number;
      conversionRate: number;
      formSubmissionCount: number;
    };
    knowledgeUrls: Array<{
      __typename?: 'KnowledgeUrl';
      id: string;
      createdAt: any;
      url: string;
      status: ScrapingStatus;
    }>;
    domain?: {
      __typename?: 'Domain';
      name: string;
      id?: string | null;
      isInternal: boolean;
    } | null;
    conversationStarters: Array<{
      __typename?: 'WebsiteQuickAction';
      id: string;
      title: string;
      message: string;
    }>;
  }>;
};

export type AdminWebsiteByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminWebsiteByIdQuery = {
  __typename?: 'Query';
  assistantWebsite?: {
    __typename?: 'AssistantWebsite';
    instructions: string;
    emails?: Array<string> | null;
    id: string;
    name: string;
    font?: string | null;
    logoURL?: string | null;
    headline?: string | null;
    fontColor?: string | null;
    subHeadline?: string | null;
    description?: string | null;
    reachedFreeLimit: boolean;
    files: Array<{
      __typename?: 'AssistantFile';
      createdAt: any;
      id: string;
      url: string;
    }>;
    subscription?: {
      __typename?: 'SubscriptionEntity';
      id: string;
      active: boolean;
    } | null;
    analytics: {
      __typename?: 'Analytics';
      conversationCount: number;
      conversionRate: number;
      formSubmissionCount: number;
    };
    knowledgeUrls: Array<{
      __typename?: 'KnowledgeUrl';
      id: string;
      createdAt: any;
      url: string;
      status: ScrapingStatus;
    }>;
    domain?: {
      __typename?: 'Domain';
      name: string;
      id?: string | null;
      isInternal: boolean;
    } | null;
    conversationStarters: Array<{
      __typename?: 'WebsiteQuickAction';
      id: string;
      title: string;
      message: string;
    }>;
  } | null;
};

export type WebsiteBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type WebsiteBySlugQuery = {
  __typename?: 'Query';
  website?: {
    __typename?: 'AssistantWebsite';
    id: string;
    name: string;
    font?: string | null;
    logoURL?: string | null;
    headline?: string | null;
    fontColor?: string | null;
    subHeadline?: string | null;
    description?: string | null;
    reachedFreeLimit: boolean;
    domain?: {
      __typename?: 'Domain';
      name: string;
      id?: string | null;
      isInternal: boolean;
    } | null;
    conversationStarters: Array<{
      __typename?: 'WebsiteQuickAction';
      id: string;
      title: string;
      message: string;
    }>;
  } | null;
};

export type WebsiteByDomainQueryVariables = Exact<{
  domainName: Scalars['String']['input'];
}>;

export type WebsiteByDomainQuery = {
  __typename?: 'Query';
  website?: {
    __typename?: 'AssistantWebsite';
    id: string;
    name: string;
    font?: string | null;
    logoURL?: string | null;
    headline?: string | null;
    fontColor?: string | null;
    subHeadline?: string | null;
    description?: string | null;
    reachedFreeLimit: boolean;
    domain?: {
      __typename?: 'Domain';
      name: string;
      id?: string | null;
      isInternal: boolean;
    } | null;
    conversationStarters: Array<{
      __typename?: 'WebsiteQuickAction';
      id: string;
      title: string;
      message: string;
    }>;
  } | null;
};

export type ThreadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ThreadQuery = {
  __typename?: 'Query';
  thread?: {
    __typename?: 'AssistantThread';
    id: string;
    messages: Array<{
      __typename?: 'ThreadMessage';
      id: string;
      role: string;
      type: MessageType;
      content: string;
      fields?: Array<{
        __typename?: 'CollectInputField';
        type: string;
        label: string;
        description: string;
        placeholder: string;
      }> | null;
    }>;
  } | null;
};

export type SessionThreadsQueryVariables = Exact<{
  websiteId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
}>;

export type SessionThreadsQuery = {
  __typename?: 'Query';
  sessionThreads: Array<{ __typename?: 'AssistantThread'; id: string }>;
};

export type PlansQueryVariables = Exact<{ [key: string]: never }>;

export type PlansQuery = {
  __typename?: 'Query';
  getPlans?: Array<{ __typename?: 'Plan'; id: string; price: number }> | null;
};

export type GetStripeCustomerPortalUrlQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStripeCustomerPortalUrlQuery = {
  __typename?: 'Query';
  url: string;
};

export type MessageAddedSubscriptionVariables = Exact<{
  threadId: Scalars['String']['input'];
}>;

export type MessageAddedSubscription = {
  __typename?: 'Subscription';
  messageAdded?: {
    __typename?: 'MessageAdded';
    threadId: string;
    content: string;
  } | null;
};

export const MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ThreadMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const PlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const QuickActionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WebsiteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AnalyticsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Analytics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Analytics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmissionCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const KnowledgeUrlFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeUrl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeUrl' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AdminWebsiteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminWebsite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'analytics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Analytics' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KnowledgeUrl' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Analytics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Analytics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmissionCount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeUrl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeUrl' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CreateWebsiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateWebsite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAssistantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAssistantWebsite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminWebsite' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Analytics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Analytics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmissionCount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeUrl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeUrl' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminWebsite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'analytics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Analytics' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KnowledgeUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const UpdateWebsiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWebsite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAssistantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAssistantWebsite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminWebsite' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Analytics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Analytics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmissionCount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeUrl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeUrl' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminWebsite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'analytics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Analytics' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KnowledgeUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const GeneratePresignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GeneratePresignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GeneratePreSignedURLInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generatePreSignedURL' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resourceUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const StartAssistantThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartAssistantThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thread' },
            name: { kind: 'Name', value: 'startAssistantThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ThreadMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ReplayToThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReplayToThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'replay' },
            name: { kind: 'Name', value: 'replayToAssistantThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ThreadMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SubmitFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitFormInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CreatePaymentUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'successUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPaymentUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'successUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'successUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelUrl' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AddKnowledgeUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddKnowledgeUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'knowledgeUrls' },
            name: { kind: 'Name', value: 'addKnowledgeUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'url' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const DeleteKnowledgeUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteKnowledgeUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'knowledgeUrls' },
            name: { kind: 'Name', value: 'deleteKnowledgeUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RefreshKnowledgeUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshKnowledgeUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'knowledgeUrls' },
            name: { kind: 'Name', value: 'refreshKnowledgeUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ConnectExternalDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectExternalDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domainName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectExternalDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domainName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domainName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const DisConnectDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisConnectDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domainId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domainId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domainId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Message_AddedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'MESSAGE_ADDED' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'threadId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageAdded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'threadId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'threadId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyWebsitesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyWebsites' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assistantWebsites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminWebsite' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Analytics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Analytics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmissionCount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeUrl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeUrl' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminWebsite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'analytics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Analytics' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KnowledgeUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const AdminWebsiteByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminWebsiteById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assistantWebsite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminWebsite' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantFile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionEntity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Analytics' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Analytics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmissionCount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeUrl' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeUrl' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminWebsite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'analytics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Analytics' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KnowledgeUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WebsiteBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebsiteBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'website' },
            name: { kind: 'Name', value: 'assistantWebsiteBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Website' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const WebsiteByDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebsiteByDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domainName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'website' },
            name: { kind: 'Name', value: 'getWebsiteByDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domainName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'domainName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Website' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickAction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WebsiteQuickAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Website' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssistantWebsite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'font' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subHeadline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reachedFreeLimit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationStarters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuickAction' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Thread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thread' },
            name: { kind: 'Name', value: 'assistantThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ThreadMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SessionThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SessionThreads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionThreads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const PlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Plans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const GetStripeCustomerPortalUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStripeCustomerPortalUrl' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'url' },
            name: { kind: 'Name', value: 'getStripeCustomerPortalUrl' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MessageAddedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'messageAdded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'threadId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageAdded' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'threadId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'threadId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    CreateWebsite(
      variables: CreateWebsiteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateWebsiteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateWebsiteMutation>(
            CreateWebsiteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateWebsite',
        'mutation',
      );
    },
    UpdateWebsite(
      variables: UpdateWebsiteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateWebsiteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateWebsiteMutation>(
            UpdateWebsiteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateWebsite',
        'mutation',
      );
    },
    GeneratePresignedUrl(
      variables: GeneratePresignedUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GeneratePresignedUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GeneratePresignedUrlMutation>(
            GeneratePresignedUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GeneratePresignedUrl',
        'mutation',
      );
    },
    StartAssistantThread(
      variables: StartAssistantThreadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartAssistantThreadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StartAssistantThreadMutation>(
            StartAssistantThreadDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'StartAssistantThread',
        'mutation',
      );
    },
    ReplayToThread(
      variables: ReplayToThreadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReplayToThreadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReplayToThreadMutation>(
            ReplayToThreadDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ReplayToThread',
        'mutation',
      );
    },
    SubmitForm(
      variables: SubmitFormMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubmitFormMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubmitFormMutation>(SubmitFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SubmitForm',
        'mutation',
      );
    },
    CreatePaymentUrl(
      variables: CreatePaymentUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreatePaymentUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePaymentUrlMutation>(
            CreatePaymentUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreatePaymentUrl',
        'mutation',
      );
    },
    AddKnowledgeUrl(
      variables: AddKnowledgeUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddKnowledgeUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddKnowledgeUrlMutation>(
            AddKnowledgeUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AddKnowledgeUrl',
        'mutation',
      );
    },
    DeleteKnowledgeUrl(
      variables: DeleteKnowledgeUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteKnowledgeUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteKnowledgeUrlMutation>(
            DeleteKnowledgeUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteKnowledgeUrl',
        'mutation',
      );
    },
    RefreshKnowledgeUrl(
      variables: RefreshKnowledgeUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RefreshKnowledgeUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RefreshKnowledgeUrlMutation>(
            RefreshKnowledgeUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RefreshKnowledgeUrl',
        'mutation',
      );
    },
    ConnectExternalDomain(
      variables: ConnectExternalDomainMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ConnectExternalDomainMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConnectExternalDomainMutation>(
            ConnectExternalDomainDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ConnectExternalDomain',
        'mutation',
      );
    },
    DisConnectDomain(
      variables: DisConnectDomainMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DisConnectDomainMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DisConnectDomainMutation>(
            DisConnectDomainDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DisConnectDomain',
        'mutation',
      );
    },
    MESSAGE_ADDED(
      variables: Message_AddedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Message_AddedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Message_AddedSubscription>(
            Message_AddedDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'MESSAGE_ADDED',
        'subscription',
      );
    },
    MyWebsites(
      variables?: MyWebsitesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MyWebsitesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MyWebsitesQuery>(MyWebsitesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'MyWebsites',
        'query',
      );
    },
    AdminWebsiteById(
      variables: AdminWebsiteByIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AdminWebsiteByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AdminWebsiteByIdQuery>(
            AdminWebsiteByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AdminWebsiteById',
        'query',
      );
    },
    WebsiteBySlug(
      variables: WebsiteBySlugQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<WebsiteBySlugQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebsiteBySlugQuery>(WebsiteBySlugDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'WebsiteBySlug',
        'query',
      );
    },
    WebsiteByDomain(
      variables: WebsiteByDomainQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<WebsiteByDomainQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebsiteByDomainQuery>(
            WebsiteByDomainDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'WebsiteByDomain',
        'query',
      );
    },
    Thread(
      variables: ThreadQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ThreadQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ThreadQuery>(ThreadDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Thread',
        'query',
      );
    },
    SessionThreads(
      variables: SessionThreadsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SessionThreadsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SessionThreadsQuery>(
            SessionThreadsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SessionThreads',
        'query',
      );
    },
    Plans(
      variables?: PlansQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PlansQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlansQuery>(PlansDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Plans',
        'query',
      );
    },
    GetStripeCustomerPortalUrl(
      variables?: GetStripeCustomerPortalUrlQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetStripeCustomerPortalUrlQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetStripeCustomerPortalUrlQuery>(
            GetStripeCustomerPortalUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetStripeCustomerPortalUrl',
        'query',
      );
    },
    messageAdded(
      variables: MessageAddedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MessageAddedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MessageAddedSubscription>(
            MessageAddedDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'messageAdded',
        'subscription',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
