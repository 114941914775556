import React from 'react';

export const SiteLeapUserAvatar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F8F9FC" />
      <g opacity="0.08">
        <rect
          x="0.225"
          y="0.225"
          width="23.55"
          height="23.55"
          rx="11.775"
          stroke="#101828"
          strokeWidth="0.45"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 7.2C10.8399 7.2 9.89966 8.1402 9.89966 9.3C9.89966 10.4598 10.8399 11.4 11.9997 11.4C13.1595 11.4 14.0997 10.4598 14.0997 9.3C14.0997 8.1402 13.1595 7.2 11.9997 7.2ZM8.69966 9.3C8.69966 7.47746 10.1771 6 11.9997 6C13.8222 6 15.2997 7.47746 15.2997 9.3C15.2997 11.1225 13.8222 12.6 11.9997 12.6C10.1771 12.6 8.69966 11.1225 8.69966 9.3ZM10.3954 13.5C10.4297 13.5 10.4644 13.5 10.4997 13.5H13.4997C13.5349 13.5 13.5697 13.5 13.6039 13.5C14.3403 13.4997 14.8431 13.4995 15.2705 13.6292C16.2293 13.92 16.9796 14.6703 17.2705 15.6291C17.4001 16.0566 17.4 16.5594 17.3997 17.2958C17.3997 17.33 17.3997 17.3647 17.3997 17.4C17.3997 17.7314 17.131 18 16.7997 18C16.4683 18 16.1997 17.7314 16.1997 17.4C16.1997 16.519 16.1931 16.2115 16.1222 15.9775C15.9476 15.4022 15.4975 14.952 14.9222 14.7775C14.6881 14.7065 14.3806 14.7 13.4997 14.7H10.4997C9.61867 14.7 9.31118 14.7065 9.07715 14.7775C8.50186 14.952 8.05168 15.4022 7.87717 15.9775C7.80617 16.2115 7.79966 16.519 7.79966 17.4C7.79966 17.7314 7.53103 18 7.19966 18C6.86829 18 6.59966 17.7314 6.59966 17.4C6.59966 17.3647 6.59965 17.33 6.59963 17.2958C6.59936 16.5594 6.59918 16.0566 6.72884 15.6291C7.01969 14.6703 7.77 13.92 8.7288 13.6292C9.15624 13.4995 9.65905 13.4997 10.3954 13.5Z"
        fill="#667085"
      />
    </svg>
  );
};
