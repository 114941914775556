import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react';

export interface SVGProps extends ChakraIconProps {
  size?: ChakraIconProps['boxSize'] | number | string;
}

export function SVG(props: SVGProps) {
  return (
    // @ts-ignore
    <ChakraIcon {...props} boxSize={props.size || props.boxSize || '20px'} />
  );
}
