import { useToken } from '@chakra-ui/react';

function lightenColor(color: string, factor: number) {
  // Convert hex to RGB
  const hex = color.replace(/^#/, '');
  const rgb = parseInt(hex, 16);
  let r = (rgb >> 16) & 0xff;
  let g = (rgb >> 8) & 0xff;
  let b = rgb & 0xff;

  // Adjust brightness
  r = Math.round(r * factor);
  g = Math.round(g * factor);
  b = Math.round(b * factor);

  // Ensure values are within the valid range (0-255)
  r = Math.min(255, r);
  g = Math.min(255, g);
  b = Math.min(255, b);

  // Convert back to hex
  const result =
    '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return result;
}

export const useColors = () => {
  const [chakraPrimaryColor] = useToken('colors', ['brand.primary']);
  const primaryColor = chakraPrimaryColor || '#000000';
  const secondaryColor = lightenColor(primaryColor, 4);
  return { primaryColor, secondaryColor };
};
