import { GraphQLClient } from 'graphql-request';
import { getSdk } from './generated-schema';

const NX_GRAPHQL_URI =
  typeof process !== 'undefined' ? process.env.NX_GRAPHQL_URI : undefined;

const graphqlClient = new GraphQLClient(
  NX_GRAPHQL_URI ?? 'https://api.siteleap.wuilt.dev/graphql',
  {
    credentials: 'include',
  },
);

export const api = getSdk(graphqlClient);
