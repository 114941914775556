import { SVG, SVGProps } from '../SVG';

interface SpeedometerIconProps extends SVGProps {}

const SpeedometerIcon: React.FC<SpeedometerIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.16602 10C4.16602 6.77834 6.77769 4.16667 9.99935 4.16667M13.7493 6.25L9.99927 10M18.3327 10C18.3327 14.6024 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6024 1.66602 10C1.66602 5.39763 5.39698 1.66667 9.99935 1.66667C14.6017 1.66667 18.3327 5.39763 18.3327 10ZM10.8327 10C10.8327 10.4602 10.4596 10.8333 9.99935 10.8333C9.53911 10.8333 9.16602 10.4602 9.16602 10C9.16602 9.53976 9.53911 9.16667 9.99935 9.16667C10.4596 9.16667 10.8327 9.53976 10.8327 10Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { SpeedometerIcon };
