import { AdminWebsiteFragment } from '../graphql';

const BrandPrimaryColorCssVarName = '--chakra-colors-brand-primary';
export const BrandPrimaryColor = `var(${BrandPrimaryColorCssVarName})`;

export function updateBrandColors(website: AdminWebsiteFragment) {
  const brandColor = document.documentElement.style.getPropertyValue(
    BrandPrimaryColorCssVarName,
  );

  if (brandColor === website.fontColor) return;

  document.documentElement.style.setProperty(
    BrandPrimaryColorCssVarName,
    website.fontColor || '#000000',
  );
}
