import { ChakraProvider, Stack } from '@chakra-ui/react';
import { LoaderFunction, Outlet } from 'react-router-dom';
import { useLocale } from '@wuilt/app-core';
import { getTheme } from '../../libs/chakra';
import SiteLeapHeader from './SiteLeapHeader';
import { IntlProvider } from 'react-intl';
import { api, PlanFragment } from '@leap/frontend-shared';

import messages from '../../translations/extracted-messages.json';
import LoadingBar from './LoadingBar';
import { useSiteRouteData } from '../Site';
import { ApolloProvider } from '@apollo/client';
import initApollo from '../../../graphql/client';

export type TRootLoaderReturn = {
  plan: PlanFragment;
};

export const loader: LoaderFunction = async ({ params }) => {
  const { getPlans } = await api.Plans();
  return {
    plan: getPlans?.[0],
  };
};

export function Root() {
  const { locale } = useLocale();
  const siteRouteData = useSiteRouteData();
  const website = siteRouteData?.website;
  const client = initApollo();

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={getTheme(website, locale.dir)}>
        <IntlProvider
          defaultLocale="en-US"
          locale={locale.code}
          messages={messages}
        >
          <LoadingBar />
          <Stack spacing="0" minHeight="100vh">
            <SiteLeapHeader />
            <Outlet />
          </Stack>
        </IntlProvider>
      </ChakraProvider>
    </ApolloProvider>
  );
}
