import {ApolloClient, createHttpLink, InMemoryCache, split} from '@apollo/client';
import {createClient} from "graphql-ws";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {getMainDefinition} from '@apollo/client/utilities';


const httpLink = createHttpLink({
  uri: 'https://graphql.wuilt.dev',
  credentials: 'include',
});
const isBrowser = typeof window !== 'undefined'
const wsLink = isBrowser ? new GraphQLWsLink(
  createClient({
    url: "wss://api.siteleap.wuilt.dev/graphql",
    // wss use to fix Uncaught (in promise) DOMException: Failed to construct 'WebSocket': An insecure WebSocket connection may not be initiated from a page loaded over HTTPS.
    // webSocketImpl: WebSocket,
  }),
) : null;

const client = new ApolloClient({
  link: isBrowser ? split(
    ({query}) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    // @ts-ignore
    wsLink,
    httpLink,
  ) : httpLink,
  cache: new InMemoryCache({}),
});

export default function initApollo() {
  return client;
}
