import React from 'react';
import { TChatMessage } from '../types';
import Markdown from 'markdown-to-jsx';
import { Box, Text } from '@chakra-ui/react';

interface NormalMessageProps {
  message: TChatMessage;
}

const NormalMessage: React.FC<NormalMessageProps> = ({ message }) => {
  return (
    <Box
      listStylePosition="inside"
      whiteSpace="pre-line"
      sx={{
        a: {
          textDecoration: 'underline',
          color: 'var(--brand-primary)',
        },
      }}
    >
      {typeof message.content === 'string' ? (
        <Markdown fontWeight="500">{message.content}</Markdown>
      ) : (
        <Text fontWeight="500">{message.content}</Text>
      )}
    </Box>
  );
};

export { NormalMessage };
