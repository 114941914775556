import { AdminWebsiteFragment } from '@leap/frontend-shared';
import {
  useActionData,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import type { TSiteLoaderReturn } from './Site';

export function useSiteRouteData(): { website: AdminWebsiteFragment } {
  const loaderData = useRouteLoaderData('site') as TSiteLoaderReturn;
  const actionData = useActionData() as { website: AdminWebsiteFragment };
  const optimisticData = useNavigation().json as AdminWebsiteFragment;

  return {
    website: {
      ...loaderData?.website,
      ...optimisticData,
      ...actionData?.website,
    },
  };
}
