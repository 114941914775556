import { Stack, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from '@leap/react-icons';
import React from 'react';

const Theme = {
  success: {
    bgColor: 'success.25',
    borderColor: 'success.300',
    iconColor: 'success.600',
    textColor: 'success.700',
  },
  error: {
    bgColor: 'error.25',
    borderColor: 'error.300',
    iconColor: 'error.600',
    textColor: 'error.700',
  },
};

interface AlertProps {
  text: React.ReactNode;
  theme?: keyof typeof Theme;
}

const Alert: React.FC<AlertProps> = ({ text, theme = 'error' }) => {
  return (
    <Stack
      direction="row"
      spacing="8px"
      align="center"
      backgroundColor={Theme[theme].bgColor}
      padding="16px"
      marginTop="16px"
      border="1px solid"
      borderRadius="12px"
      borderColor={Theme[theme].borderColor}
      boxShadow="xs"
    >
      <InfoCircleIcon color={Theme[theme].iconColor} />
      <Text fontWeight="600" color={Theme[theme].textColor}>
        {text}
      </Text>
    </Stack>
  );
};

export { Alert };
