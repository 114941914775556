import { Box, Stack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  ChatMessageRoleEnum,
  ChatMessageTypeEnum,
  TChatMessage,
  TFormFieldValue,
  TUserInfo,
} from '../types';
import { ChatMessage } from './ChatMessage';
import { BeatLoader } from './BeatLoader';
import { ScrollToBottomButton } from './ScrollToBottomButton';
import { NormalMessage } from './NormalMessage';

interface ChatMessagesProps {
  maxHeight: string;
  messages: TChatMessage[];
  isOpen: boolean;
  isTyping: boolean;
  receiverInfo: TUserInfo;
  senderInfo: TUserInfo;
  onSubmitForm: (fields: TFormFieldValue[]) => void;
  onSendMessage: (message: string) => void;
  text?: any;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({
  maxHeight,
  messages,
  isOpen,
  isTyping,
  receiverInfo,
  senderInfo,
  onSubmitForm,
  onSendMessage,
  text,
}) => {
  const [isScrolledUp, setIsScrolled] = useState(false);
  const messagesAreaRef = useRef<HTMLDivElement>(null);
  const previousMessagesLength = useRef(messages.length);

  const scrollToBottom = () => {
    if (messagesAreaRef.current) {
      messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const element = event.target as HTMLDivElement;
    const availableScroll = element.scrollHeight - element.clientHeight;
    setIsScrolled(element.scrollTop + 10 < availableScroll);
  };

  useEffect(() => {
    // scroll down after receiving a new message
    if (!isScrolledUp && messages.length > previousMessagesLength.current) {
      previousMessagesLength.current = messages.length;
      scrollToBottom();
    }
  }, [isScrolledUp, messages.length]);

  useEffect(() => {
    // scroll down to show the loader after sending a message
    if (isTyping) {
      scrollToBottom();
    }
  }, [isTyping]);

  return (
    <Box position="relative">
      <Stack
        ref={messagesAreaRef}
        spacing="20px"
        overflowY="auto"
        overflowX="hidden"
        paddingInlineEnd={{ base: '10px', md: '100px' }}
        scrollBehavior="smooth"
        marginBottom={4}
        maxHeight={`calc( ${maxHeight} - 100px )`}
        height={isOpen ? `calc( ${maxHeight} - 100px )` : 0}
        transition="all 500ms"
        onScroll={handleScroll}
      >
        {isOpen && (
          <>
            {messages?.map((message, index) => {
              const isSender = message.role === ChatMessageRoleEnum.Sender;
              return (
                <ChatMessage
                  key={message.id}
                  message={message}
                  info={isSender ? senderInfo : receiverInfo}
                  isLastMessage={index === messages.length - 1}
                  onSubmitForm={onSubmitForm}
                  onSendMessage={onSendMessage}
                />
              );
            })}

            {isTyping && (
              <ChatMessage
                message={{
                  id: 'typing-message-id',
                  role: ChatMessageRoleEnum.Receiver,
                  type: ChatMessageTypeEnum.Normal,
                  content: <BeatLoader />,
                }}
                info={receiverInfo}
                isLastMessage
              />
            )}
          </>
        )}
      </Stack>
      {text !== 'undefined' && (
        <Box
          padding="8px 14px"
          borderRadius="8px"
          borderStartStartRadius="0"
          mb="10px"
          background="#F2F4F7"
          color="gray.800"
          fontSize="14px"
          fontWeight="500"
          lineHeight="24px"
        >
          <NormalMessage
            message={{
              id: '',
              content: text,
              type: ChatMessageTypeEnum.Normal,
              role: ChatMessageRoleEnum.Receiver,
            }}
          />
        </Box>
      )}
      {isScrolledUp && <ScrollToBottomButton scrollToBottom={scrollToBottom} />}
    </Box>
  );
};

export { ChatMessages };
