import React from 'react';
import { Box } from '@chakra-ui/react';
import {
  ChatMessageTypeEnum,
  TChatMessage,
  TFormFieldValue,
  TUserInfo,
} from '../types';
import { ChatDisclaimer, ChatInput, ChatMessages } from '../components';

interface ChatProps {
  maxHeight: string;
  messages: TChatMessage[];
  isOpen?: boolean;
  isTyping?: boolean;
  receiverInfo: TUserInfo;
  senderInfo: TUserInfo;
  disableMessage?: React.ReactNode;
  onSendMessage: (message: string) => void;
  onSubmitForm: (fields: TFormFieldValue[]) => void;
  text?: any;
}

export const Chat: React.FC<ChatProps> = ({
  maxHeight,
  messages,
  isOpen,
  isTyping,
  receiverInfo,
  senderInfo,
  disableMessage,
  onSendMessage,
  onSubmitForm,
  text,
}) => {
  const isFormRequested =
    messages.at(-1)?.type === ChatMessageTypeEnum.CollectInput;

  return (
    <Box>
      <ChatMessages
        maxHeight={maxHeight}
        messages={messages}
        isOpen={!!isOpen}
        isTyping={!!isTyping}
        receiverInfo={receiverInfo}
        senderInfo={senderInfo}
        onSendMessage={onSendMessage}
        onSubmitForm={onSubmitForm}
        text={text}
      />
      {disableMessage ? (
        disableMessage
      ) : (
        <ChatInput
          isTyping={!!isTyping}
          receiverInfo={receiverInfo}
          senderInfo={senderInfo}
          isDisabled={isFormRequested || !!disableMessage}
          onSendMessage={onSendMessage}
        />
      )}
      <ChatDisclaimer />
    </Box>
  );
};
