import { createBrowserRouter } from 'react-router-dom';
import { HomeRoute } from './screens/Home';
import { loader, Root } from './screens/Root';
import { ErrorPage } from './screens/Error';
import { SiteRoute } from './screens/Site';

export const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    loader,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [HomeRoute, SiteRoute],
    shouldRevalidate: () => false,
  },
]);
