import { Stack, Heading, Box, Image, Button } from '@chakra-ui/react';
import React from 'react';
import { WebsiteFragment } from '../../graphql';
import { QuickActions } from './QuickActions';
import { PlusIcon } from '@leap/react-icons';

interface WebsiteInfoProps {
  compact: boolean;
  website: WebsiteFragment | undefined;
  sendMessage: (message: string) => void;
  resetChat: () => void;
  maxHeight: string;
}

const WebsiteInfo: React.FC<WebsiteInfoProps> = ({
  compact,
  website,
  sendMessage,
  resetChat,
  maxHeight,
}) => {
  return (
    <Box
      boxShadow={compact ? 'md' : undefined}
      padding="10px 20px 6px 20px"
      maxHeight={maxHeight}
      height={compact ? '62px' : maxHeight}
      overflowY="auto"
      overflowX="hidden"
      display={compact ? 'unset' : 'flex'}
      justifyContent="center"
    >
      <Stack
        position="relative"
        align="center"
        justify="center"
        maxWidth="768px"
        margin="auto"
        spacing="0"
        height="100%"
      >
        <Stack
          direction="row"
          justify={{ base: 'space-between', md: 'center' }}
          width={compact ? '100%' : 'auto'}
          align="center"
          marginBottom={!compact ? '2.5rem' : '0px'}
        >
          {website?.logoURL && (
            <Image
              src={website?.logoURL}
              width="auto"
              height={compact ? '40px' : '50px'}
              transition="all 300ms"
            />
          )}
          {compact && (
            <Button
              position="absolute"
              insetInlineEnd="0"
              background="brand.primary"
              color="white"
              height="40px"
              _hover={{
                background: 'brand.primary',
                filter: 'saturate(0.5)',
              }}
              _active={{
                background: 'brand.primary',
                filter: 'saturate(0.5)',
              }}
              leftIcon={<PlusIcon />}
              onClick={resetChat}
            >
              New
            </Button>
          )}
        </Stack>
        {!compact && (
          <>
            {website?.headline && (
              <Heading
                color="gray.800"
                fontSize={{ base: '36px', md: '52px' }}
                fontWeight="600"
                letterSpacing={{ base: '-0.72px', md: '-2.4px' }}
                lineHeight={{ base: '44px', md: '62px' }}
                textShadow="sm"
                textAlign="center"
                marginBottom="2rem"
              >
                {website?.headline}
              </Heading>
            )}
            {website?.subHeadline && (
              <Heading
                variant="h2"
                color="gray.500"
                fontSize={{ base: '16px', md: '24px' }}
                letterSpacing={{ base: '-0.32px', md: '-0.48px' }}
                lineHeight={{ base: '24px', md: '32px' }}
                fontWeight="500"
                textAlign="center"
                marginBottom="2rem"
              >
                {website?.subHeadline}
              </Heading>
            )}
            {!!website?.conversationStarters?.length && (
              <QuickActions
                quickActions={website?.conversationStarters}
                sendMessage={sendMessage}
              />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export { WebsiteInfo };
