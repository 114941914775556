import { Stack, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { ArrowRightIcon } from '@leap/react-icons';
import { QuickActionFragment } from '../../graphql';

interface QuickActionsProps {
  quickActions: QuickActionFragment[];
  sendMessage: (message: string) => void;
}

const QuickActions: React.FC<QuickActionsProps> = ({
  quickActions,
  sendMessage,
}) => {
  const handleClick = (message: string) => {
    sendMessage(message);
  };

  return (
    <Stack
      direction="row"
      spacing="16px"
      flexWrap="wrap"
      justify="center"
      maxWidth="850px"
    >
      {quickActions?.map((action) => (
        <Button
          key={action.id}
          title="Send"
          height="fit-content"
          padding="12px 16px"
          fontSize="18px"
          fontWeight="600"
          width={{ base: '100%', sm: '200px' }}
          colorScheme="brand"
          bg="brand.primary"
          boxShadow="lg"
          whiteSpace="break-spaces"
          wordBreak="break-all"
          _hover={{ bg: 'brand.primary', boxShadow: '2xl' }}
          _active={{ bg: 'brand.primary' }}
          onClick={() => handleClick(action.message)}
        >
          <Stack
            opacity="0.8"
            direction="row"
            justify="space-between"
            width="100%"
            _hover={{ opacity: '1' }}
          >
            <Text textAlign="start">{action.title}</Text>
            <Stack justify="end" height="80px">
              <ArrowRightIcon />
            </Stack>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
};

export { QuickActions };
