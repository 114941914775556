import { SVG, SVGProps } from '../SVG';

interface ArrowDownIconProps extends SVGProps {}

const ArrowDownIcon: React.FC<ArrowDownIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.99984 4.16675V15.8334M9.99984 15.8334L15.8332 10.0001M9.99984 15.8334L4.1665 10.0001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { ArrowDownIcon };
