import { SVG, SVGProps } from '../SVG';

interface PlusIconProps extends SVGProps {}

const PlusIcon: React.FC<PlusIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.99935 4.16667V15.8333M4.16602 10H15.8327"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { PlusIcon };
