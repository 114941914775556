import { extendTheme, Theme } from '@chakra-ui/react';
import {
  AdminWebsiteFragment,
  ChakraDefaultTheme,
} from '@leap/frontend-shared';

export const getTheme = (website: AdminWebsiteFragment, direction: string) =>
  // @ts-ignore
  extendTheme({
    ...ChakraDefaultTheme,
    direction,
    colors: {
      ...ChakraDefaultTheme.colors,
      defaultButton: {
        500: '#3E4784',
        600: '#293056',
        700: '#293056',
      },
      brand: {
        primary: website?.fontColor || '#000',
        50: '#F8F9FC',
        100: '#EAECF5',
        200: '#D5D9EB',
        300: '#B3B8DB',
        400: '#717BBC',
        500: '#4E5BA6',
        600: '#3E4784',
        700: '#363F72',
        800: '#293056',
        900: '#101323',
      },
    },
    components: {
      ...ChakraDefaultTheme.components,
      Button: {
        ...ChakraDefaultTheme.components?.Button,
        defaultProps: {
          ...ChakraDefaultTheme.components?.Button?.defaultProps,
          colorScheme: 'defaultButton',
        },
      },
      Checkbox: {
        ...ChakraDefaultTheme.components?.Checkbox,
        defaultProps: {
          ...ChakraDefaultTheme.components?.Checkbox?.defaultProps,
          colorScheme: 'defaultButton',
        },
      },
    },
  } as Partial<Theme>);
