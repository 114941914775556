import {
  ChatMessageRoleEnum,
  ChatMessageTypeEnum,
  FormFieldEnum,
  TChatMessage,
} from '@leap/react-chat';
import { MessageType, ThreadMessage } from '../graphql';

export function convertApiResponseToChatMessages(
  apiMessages: ThreadMessage[] | undefined = [],
  sentMessage: string | undefined | null,
): TChatMessage[] {
  const optimisticMessage: TChatMessage | undefined = sentMessage
    ? {
        id: 'ui-optimistic-message-id',
        content: sentMessage,
        role: ChatMessageRoleEnum.Sender,
        type: ChatMessageTypeEnum.Normal,
      }
    : undefined;

  const convertedMessages: TChatMessage[] = apiMessages.map((m) => ({
    id: m.id,
    role:
      m.role === 'assistant'
        ? ChatMessageRoleEnum.Receiver
        : ChatMessageRoleEnum.Sender,
    type:
      m.type === MessageType.CollectInput
        ? ChatMessageTypeEnum.CollectInput
        : ChatMessageTypeEnum.Normal,
    content: m.content,
    formFields: m.fields?.map((f) => ({
      name: f.label,
      label: f.label,
      type: FormFieldEnum.Text,
      description: f?.description,
      placeholder: f.placeholder,
    })),
  }));

  if (optimisticMessage) {
    return [...convertedMessages, optimisticMessage];
  }

  return convertedMessages;
}
