import { RouteObject } from 'react-router-dom';
import { AnalyticsRoute } from '../Analytics';
import { EditSiteRoute } from '../EditSite';

export const SiteRoute: RouteObject = {
  id: 'site',
  path: 'site',
  lazy: () => import('./Site'),
  children: [EditSiteRoute, AnalyticsRoute],
  shouldRevalidate: ({ json }) => !!(json as any)?.fontColor, // validate only in case of font-color changed
};

export { useSiteRouteData } from './useSiteRouteData';
