import { SVG, SVGProps } from '../SVG';

interface InfoCircleIconProps extends SVGProps {}

const InfoCircleIcon: React.FC<InfoCircleIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.99967 10.6667V8.00001M7.99967 5.33334H8.00634M14.6663 8.00001C14.6663 11.6819 11.6816 14.6667 7.99967 14.6667C4.31778 14.6667 1.33301 11.6819 1.33301 8.00001C1.33301 4.31811 4.31778 1.33334 7.99967 1.33334C11.6816 1.33334 14.6663 4.31811 14.6663 8.00001Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { InfoCircleIcon };
