import { SVG, SVGProps } from '../SVG';

interface ArrowRightIconProps extends SVGProps {}

const ArrowRightIcon: React.FC<ArrowRightIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.16602 10H15.8327M15.8327 10L9.99935 4.16669M15.8327 10L9.99935 15.8334"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { ArrowRightIcon };
