import { Box, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import {
  ChatMessageRoleEnum,
  ChatMessageTypeEnum,
  TChatMessage,
  TFormFieldValue,
  TUserInfo,
} from '../types';
import { NormalMessage } from './NormalMessage';
import { FormMessage } from './FormMessage';
import { SiteLeapUserAvatar } from './SiteLeapUserAvatar';

const ContentComponentMapper = {
  [ChatMessageTypeEnum.Normal]: NormalMessage,
  [ChatMessageTypeEnum.CollectInput]: FormMessage,
};

interface ChatMessageProps {
  message: TChatMessage;
  info: TUserInfo;
  isLastMessage: boolean;
  onSubmitForm?: (fields: TFormFieldValue[]) => void;
  onSendMessage?: (message: string) => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  info,
  isLastMessage,
  onSubmitForm,
  onSendMessage,
}) => {
  const isSender = message.role === ChatMessageRoleEnum.Sender;
  const ContentComponent = ContentComponentMapper[message.type];
  return (
    <Stack direction="row">
      {info.image ? (
        <Image
          width="24px"
          minWidth="24px"
          height="24px"
          minHeight="24px"
          border="0.45px solid #B3B8DB"
          src={info.image}
          borderRadius="100px"
          overflow="hidden"
          objectFit="contain"
        />
      ) : (
        <Box minH="24px" minW="24px">
          <SiteLeapUserAvatar />
        </Box>
      )}
      <Stack spacing="2px">
        <Text color="gray.800" fontSize="14px" fontWeight="600">
          {info.name}
        </Text>
        <Box
          padding="8px 14px"
          borderRadius="8px"
          borderStartStartRadius="0"
          {...(!isSender && {
            border: '1px solid',
            borderColor: '#EAECF0',
          })}
          background="#F2F4F7"
          color="gray.800"
          fontSize="14px"
          fontWeight="500"
          lineHeight="24px"
        >
          <ContentComponent
            message={message}
            isLastMessage={isLastMessage}
            onSubmitForm={onSubmitForm!}
            onSendMessage={onSendMessage!}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export { ChatMessage };
