import { IconButton } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ArrowDownIcon } from '@leap/react-icons';
import React from 'react';

interface ScrollToBottomButtonProps {
  scrollToBottom: () => void;
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({
  scrollToBottom,
}) => {
  return (
    <StyledIconButton
      aria-label="Scroll to bottom"
      send="Scroll to bottom"
      icon={<ArrowDownIcon />}
      isRound
      colorScheme="green"
      position="absolute"
      bottom="10px"
      left="50%"
      transform="translateX(-50%)"
      width="fit-content"
      padding="10px"
      opacity={0.6}
      onClick={scrollToBottom}
    />
  );
};

export { ScrollToBottomButton };

/**
 * Styles
 */

const fade = keyframes`
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;

const StyledIconButton = styled(IconButton)`
  animation: ${fade} 500ms;
`;
