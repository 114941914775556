import { Avatar, Button, Hide, Stack, Text } from '@chakra-ui/react';
import { AsteriskIcon, SpeedometerIcon } from '@leap/react-icons';
import { useAuth } from '@wuilt/app-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

const NAME = 'Siteleap';
export const MainHeaderHeight = 70;

interface SiteLeapHeaderProps {}

const SiteLeapHeader: React.FC<SiteLeapHeaderProps> = ({}) => {
  const { user } = useAuth();
  const { siteId } = useParams();

  return (
    <Stack
      direction="row"
      justify="space-between"
      align="center"
      padding="12px 16px"
      height={MainHeaderHeight}
    >
      <Stack direction="row" align="center" spacing="6px" as={Link} to="/">
        <AsteriskIcon color="brand.500" size="40px" />
        <Text
          color="brand.700"
          fontSize="32px"
          fontWeight="700"
          letterSpacing="-0.64px"
        >
          {NAME}
        </Text>
      </Stack>

      <Stack direction="row" align="center" spacing="24px">
        {!!siteId && (
          <Hide below="md">
            <Button
              variant="outline"
              leftIcon={<SpeedometerIcon />}
              as={Link}
              to={`/site/${siteId}/analytics`}
            >
              <FormattedMessage defaultMessage="Dashboard" id="hzSNj4" />
            </Button>
          </Hide>
        )}
        <Avatar name={user?.name?.trim()} />
      </Stack>
    </Stack>
  );
};

export default SiteLeapHeader;
