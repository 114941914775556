import { SVG, SVGProps } from '../SVG';

interface AsteriskIconProps extends SVGProps {}

const AsteriskIcon: React.FC<AsteriskIconProps> = (props) => {
  return (
    <SVG viewBox="0 0 40 41" fill="none" {...props}>
      <path
        d="M20.0003 7.16667V33.8333M30.0003 10.5L10.0003 30.5M33.3337 20.5H6.66699M30.0003 30.5L10.0003 10.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { AsteriskIcon };
